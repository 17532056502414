//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LOGOUT } from '~/store/auth/types.auth'
import userMenu from '~/constants/user-menu.const'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'PopoverUserMenu',
  components: {
    PopoverDefault: () => import('./PopoverDefault.vue'),
  },
  data() {
    return {
      links: userMenu,
    }
  },
  methods: {
    handleRoute() {
      this.$emit('click:close')
    },
    handleLogout() {
      this.$emit('click:close')
      this.$store.dispatch(`auth/${LOGOUT}`)
      localStorage.setItem('tempUser', uuidv4())
      this.$store.dispatch('cart/FETCH_CART_PRODUCTS');
      this.$nuxt.refresh()
    },
  },
}
